var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Наименование товара","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Product name-default"}},[_vm._v("Наименование товара")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Наименование товара"},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Кастомный URL (Slug)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Optional-default"}},[_vm._v("Кастомный URL (Slug)")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Кастомный URL (Slug)"},model:{value:(_vm.product.slug),callback:function ($$v) {_vm.$set(_vm.product, "slug", $$v)},expression:"product.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Артикул","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"SKU-default"}},[_vm._v("Артикул")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Серийный номер"},model:{value:(_vm.product.sku),callback:function ($$v) {_vm.$set(_vm.product, "sku", $$v)},expression:"product.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Цена","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Price-default"}},[_vm._v("Цена")]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Цена"},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Для кого","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Для кого")]),_c('b-form-select',{attrs:{"id":"select-default","options":_vm.for_whos,"value-field":"id","text-field":"name"},model:{value:(_vm.product.for_whos_id),callback:function ($$v) {_vm.$set(_vm.product, "for_whos_id", $$v)},expression:"product.for_whos_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Кол-во","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Кол-во")]),_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Кол-во"},model:{value:(_vm.product.quantity),callback:function ($$v) {_vm.$set(_vm.product, "quantity", $$v)},expression:"product.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Основная категория","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Основная категория")]),_c('v-select',{attrs:{"value-field":"id","text-field":"name","label":"name","options":_vm.mainCategories},on:{"input":_vm.getCategories},model:{value:(_vm.product.mainCategories),callback:function ($$v) {_vm.$set(_vm.product, "mainCategories", $$v)},expression:"product.mainCategories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Категория","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Категория")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value-field":"id","text-field":"name","label":"name","options":_vm.categories},on:{"input":_vm.getSubCategories},model:{value:(_vm.product.categories),callback:function ($$v) {_vm.$set(_vm.product, "categories", $$v)},expression:"product.categories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Подкатегория","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Подкатегория")]),_c('v-select',{attrs:{"label":"name","options":_vm.subCategories},model:{value:(_vm.product.subCategories),callback:function ($$v) {_vm.$set(_vm.product, "subCategories", $$v)},expression:"product.subCategories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Бренд","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"select-default"}},[_vm._v("Бренд")]),_c('b-form-select',{attrs:{"id":"select-default","options":_vm.brands,"value-field":"id","text-field":"name"},model:{value:(_vm.product.brand_id),callback:function ($$v) {_vm.$set(_vm.product, "brand_id", $$v)},expression:"product.brand_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"фото","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Изображение")]),_c('b-form-file',{attrs:{"placeholder":"Выберите файл или перетащите его сюда...","drop-placeholder":"Бросьте файл сюда..."},model:{value:(_vm.product.file),callback:function ($$v) {_vm.$set(_vm.product, "file", $$v)},expression:"product.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Скидка","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"select-default_dis"}},[_vm._v("Скидка")]),_c('b-form-select',{attrs:{"id":"select-default_dis","options":_vm.discount_id,"value-field":"id","text-field":"name"},model:{value:(_vm.product.discount_id),callback:function ($$v) {_vm.$set(_vm.product, "discount_id", $$v)},expression:"product.discount_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Процент скидки","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Процент скидки")]),_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Кол-во"},model:{value:(_vm.product.discount_percent),callback:function ($$v) {_vm.$set(_vm.product, "discount_percent", $$v)},expression:"product.discount_percent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card-code',{attrs:{"title":"Описание"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBubble)+" ")]},proxy:true}],null,true)},[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Типы товара","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"Quantity-default"}},[_vm._v("Типы товара")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","value-field":"id","label":"name","options":_vm.typeProducts,"reduce":function (category) { return category.id; }},model:{value:(_vm.product.product_type_ids),callback:function ($$v) {_vm.$set(_vm.product, "product_type_ids", $$v)},expression:"product.product_type_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Товар доступен в розничном магазине","label-for":"show_in_services","for":"show_in_services"}},[_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"id":"show_in_services","name":"show_in_services","switch":"","inline":""},model:{value:(_vm.product.is_only_on_retail_store),callback:function ($$v) {_vm.$set(_vm.product, "is_only_on_retail_store", $$v)},expression:"product.is_only_on_retail_store"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Состав","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card-code',{attrs:{"title":"Состав"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBubble)+" ")]},proxy:true}],null,true)},[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.product.compound),callback:function ($$v) {_vm.$set(_vm.product, "compound", $$v)},expression:"product.compound"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Характеристики","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card-code',{attrs:{"title":"Характеристики"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBubble)+" ")]},proxy:true}],null,true)},[_c('quill-editor',{attrs:{"options":_vm.editorOption},model:{value:(_vm.product.characteristics),callback:function ($$v) {_vm.$set(_vm.product, "characteristics", $$v)},expression:"product.characteristics"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"w-100 mt-3",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.add($event)},"disabled":_vm.addStatus}},[_vm._v(" Добавить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }