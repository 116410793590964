<!-- eslint-disable vue/no-template-shadow -->
<template>
  <!-- <b-tabs vertical nav-wrapper-class="nav-vertical">
    <b-tab active title="Главная"> -->
  <b-card>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Наименование товара"
              rules="required"
            >
              <label for="Product name-default">Наименование товара</label>
              <b-form-input
                v-model="product.name"
                :state="errors.length > 0 ? false : null"
                placeholder="Наименование товара"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Кастомный URL (Slug)"
              rules="required"
            >
              <label for="Optional-default">Кастомный URL (Slug)</label>
              <b-form-input
                v-model="product.slug"
                :state="errors.length > 0 ? false : null"
                placeholder="Кастомный URL (Slug)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Артикул"
              rules="required"
            >
              <label for="SKU-default">Артикул</label>
              <b-form-input
                v-model="product.sku"
                :state="errors.length > 0 ? false : null"
                placeholder="Серийный номер"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Цена"
              rules="required"
            >
              <label for="Price-default">Цена</label>
              <b-form-input
                v-model="product.price"
                :state="errors.length > 0 ? false : null"
                placeholder="Цена"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Для кого"
              rules="required"
            >
              <label for="Quantity-default">Для кого</label>

              <b-form-select
                id="select-default"
                v-model="product.for_whos_id"
                :options="for_whos"
                value-field="id"
                text-field="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Кол-во"
              rules="required"
            >
              <label for="Quantity-default">Кол-во</label>

              <b-form-input
                v-model="product.quantity"
                type="number"
                :state="errors.length > 0 ? false : null"
                placeholder="Кол-во"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Основная категория"
              rules="required"
            >
              <label for="Quantity-default">Основная категория</label>
              <v-select
                v-model="product.mainCategories"
                value-field="id"
                text-field="name"
                label="name"
                :options="mainCategories"

                @input="getCategories"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Категория"
              rules="required"
            >
              <label for="Quantity-default">Категория</label>
              <v-select
                v-model="product.categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                value-field="id"
                text-field="name"
                label="name"
                :options="categories"

                @input="getSubCategories"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Подкатегория"
              rules="required"
            >
              <label for="Quantity-default">Подкатегория</label>
              <v-select
                v-model="product.subCategories"
                label="name"
                :options="subCategories"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Бренд"
              rules="required"
            >
              <label for="select-default">Бренд</label>
              <b-form-select
                id="select-default"
                v-model="product.brand_id"
                :options="brands"
                value-field="id"
                text-field="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="фото"
              rules="required"
            >
              <label for="Quantity-default">Изображение</label>
              <b-form-file
                v-model="product.file"
                placeholder="Выберите файл или перетащите его сюда..."
                drop-placeholder="Бросьте файл сюда..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Скидка"
              rules="required"
            >
              <label for="select-default_dis">Скидка</label>
              <b-form-select
                id="select-default_dis"
                v-model="product.discount_id"
                :options="discount_id"
                value-field="id"
                text-field="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Процент скидки"
              rules="required"
            >
              <label for="Quantity-default">Процент скидки</label>

              <b-form-input
                v-model="product.discount_percent"
                type="number"
                :state="errors.length > 0 ? false : null"
                placeholder="Кол-во"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Описание"
                    rules="required"
                  >
                    <b-card-code title="Описание">
                      <!-- <b-card-text></b-card-text> -->

                      <quill-editor
                        v-model="product.description"
                        :options="editorOption"
                      />
                      <template #code>
                        {{ codeBubble }}
                      </template>
                    </b-card-code>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

        <b-col md="6">
          <b-form-group>
            <validation-provider
                #default="{ errors }"
                name="Типы товара"
                rules="required"
            >
              <label for="Quantity-default">Типы товара</label>
              <v-select
                  v-model="product.product_type_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  value-field="id"
                  label="name"
                  :options="typeProducts"
                  :reduce="category => category.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" >
          <b-form-group
            label="Товар доступен в розничном магазине"
            label-for="show_in_services"
            for="show_in_services"
          >
            <b-form-checkbox
              id="show_in_services"
              v-model="product.is_only_on_retail_store"
              class="mr-0 mt-50"
              name="show_in_services"
              switch
              inline
            />

          </b-form-group>
        </b-col>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Состав"
            rules="required"
          >
            <b-card-code title="Состав">
              <quill-editor
                v-model="product.compound"
                :options="editorOption"
              />
              <template #code>
                {{ codeBubble }}
              </template>
            </b-card-code>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <validation-provider
          #default="{ errors }"
          name="Характеристики"
          rules="required"
        >
          <b-card-code title="Характеристики">
            <quill-editor
              v-model="product.characteristics"
              :options="editorOption"
            />
            <template #code>
              {{ codeBubble }}
            </template>
          </b-card-code>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <!-- submit button -->
        <b-col md="12">
          <b-button
            class="w-100 mt-3"
            variant="primary"
            type="submit"
            @click.prevent="add"
            @disabled="addStatus"
          >
            Добавить

          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import {
  BFormCheckbox,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BRow,
  BTabs,
  BTab,
  BTable,
  BCardText,
} from 'bootstrap-vue'
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { $themeConfig } from '@themeConfig'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { quillEditor } from 'vue-quill-editor'
import { codeBubble } from '../forms/quill-editor/code'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    BCard,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    quillEditor,
    BCardCode,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      required,
      codeBubble,
      editorOption: {
        theme: 'bubble',
        modules: {
          toolbar: [
            ['bold', 'italic'],
            ['link', 'image'],
          ],
        },
      },
      value: ['apple', 'orange'],
      addStatus: false,
      price: '',
      col: 0,
      discount_id: [],
      mainCategories:[],
      subCategories:[],
      categories: [],
      attributes: [],
      attribute: '',
      images: [],
      values: [],
      brands: [],
      errors: [],
      typeProducts: [],
      for_whos: [],
      product: {
        id: '',
        name: '',
        slug: '',
        sku: '',
        price: '',
        quantity: 1,
        brand_id: '',
        description: '',
        featured: 0,
        status: 1,
        file: '',
        image: '',
       is_only_on_retail_store: false,
subCategories:[],
mainCategories:[],
        categories: [],
        compound: '',
        characteristics: '',
        discount_id: '',
        discount_percent: '',
        product_type_ids: [],
        for_whos_id: 1,
      },
      selectedSize: null,
    }
  },

  mounted() {
    this.getBrands()
    this.getCategories()
    this.getMainCategories()
    this.getSubCategories()
    this.getDiscouts()
    this.getProductsType()
    this.getWho()
  },

  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          // eslint-disable-next-line
            alert('login successfully')
        }
      })
    },
    getDiscouts() {

      axios.get(`${$themeConfig.app.API}v2/admin/discount`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(res => {
        this.discount_id = res.data.data
      }).catch(er => {
        console.log(er)
      })
    },
    getProductsType() {
      axios.get(`${$themeConfig.app.API}v2/admin/product-type`)
        .then(res => {
          this.typeProducts = res.data.data
        })
    },

    getBrands() {
      axios.get(`${$themeConfig.app.API}v2/admin/brands`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(res => {
        this.brands = res.data.data
      }).catch(er => {
        console.log(er)
      })
    },
    getMainCategories() {
      // axios.get(`${$themeConfig.app.API}v2/admin/main-category`, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      //   },
      // }).then(res => {
      //   this.mainCategories = res.data.data
      // }).catch(er => {
      //   console.log(er)
      // })
      axios.get(`${$themeConfig.app.API}catalog`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(res => {
        this.mainCategories = res.data.data
      }).catch(er => {
        console.log(er)
      })

    },
    getCategories() {
      // console.log(this.product.mainCategories);
      this.categories = this.product.mainCategories.categories
      this.product.categories = {}
      this.product.subCategories = {}
      // axios.get(`${$themeConfig.app.API}v2/admin/categories`, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      //   },
      // }).then(res => {

      //     // this.categories = res.data.data.filter(item => this.mainCategories.findIndex(main => item.id === main.id) !== -1)
      //     // console.log(res.data.data);
      //     // // console.log(this.product.mainCategories);

      //     this.categories = res.data

      // }).catch(er => {
      //   console.log(er)
      // })
    },
    getSubCategories() {

      // const subCategories = this.product.categories
      // const subCategories = []
      // this.product.categories.forEach(category => {
      //   category.subcategories.forEach(subcategory => subCategories.push(subcategory))
      // })
      this.subCategories = this.product.categories.subcategories
      this.product.subCategories = {}
      // axios.get(`${$themeConfig.app.API}v2/admin/subcategories`, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      //   },
      // }).then(res => {
      //   // if (this.product.categories) {
      //   //   this.subCategories = res.data.data.filter(item => item.categories.id === this.product.categories)
      //   // } else {
      //     // this.subCategories = res.data.data
      //   // }
      // }).catch(er => {
      //   console.log(er)
      // })
    },
    getWho() {
      axios.get(`${$themeConfig.app.API}v2/admin/for-who`)
        .then(res => {
          this.for_whos = res.data.data
        })
        .catch(er => {
          console.log(er)
        })
    },
    async add() {

      const categories = []

      // this.product.categories.forEach(category => {
      //   categories.push(category.id)
      // })

      // this.product.subCategories.forEach(subCategory => {
      //   categories.push(subCategory.id)
      // })

      if (this.product.categories?.id) {
        categories.push(this.product.categories.id)
      }

      if (this.product.subCategories?.id) {
        categories.push(this.product.subCategories.id)
      }

      console.log(this.product.categories?.name);
      console.log(this.product.subCategories?.name);
      console.log('this.product.file', this.product.file);

      const data = JSON.stringify({

        brand_id: this.product.brand_id,
        categories,
        characteristics: this.product.characteristics,
        description: this.product.description,
        discount_id: this.product.discount_id,
        discount_percent: this.product.discount_percent,
        featured: '0',
        for_whos_id: this.product.for_whos_id,
        image: this.product.file ? await this.getBase64(this.product.file) : '', // проверь потом
        name: this.product.name,
        price: this.product.price,
        product_type_ids: this.product.product_type_ids ?? [],
        quantity: this.product.quantity,
        slug: this.product.slug,
        status: '1',
        sku: this.product.sku,
        compound: this.product.compound,
        is_only_on_retail_store: this.product.is_only_on_retail_store ?? 0
        // 'main-categories': this.product.mainCategories,
        // subCategories: this.product.subCategories,

      })

      await axios.post(`${$themeConfig.app.API}v2/admin/products`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(res => {
          this.product = res.data.data
        }).catch(er => {
          console.log(er)
        })
        .then(() => {
          this.addStatus = true
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              text: 'Запись добавлена',
              icon: 'CheckSquareIcon',
              variant: 'success',
            },
          })
          this.$router.push(`/product/edit/${this.product.id}`)
        })
        .catch(e => {
          this.addStatus = false
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              text: e.message,
              icon: 'CheckSquareIcon',
              variant: 'dander',
            },
          })
        })
    },
    getBase64(file) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(file)
      })
    },
    previewMultiImage(event) {
      const input = event.target
      let count = input.files.length
      let index = 0
      if (input.files) {
        // eslint-disable-next-line no-plusplus
        while (count--) {
          const reader = new FileReader()
          reader.onload = e => {
            this.images.push(e.target.result)
          }
          reader.readAsDataURL(input.files[index])
          // eslint-disable-next-line no-plusplus
          index++
        }
      }
    },
    removeImages(id) {
      this.images.splice(id, 1)
    },
    async uploadImage() {
      await axios.post(`${$themeConfig.app.API}v2/admin/images`, {
        productId: `${this.$route.params.id}`,
        images: this.images,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'Appilication/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        this.addStatus = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Успешно',
            text: 'Запись добавлена',
            icon: 'CheckSquareIcon',
            variant: 'success',
          },
        })
        this.$router.push('/')
      }).catch(e => {
        this.addStatus = false
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка',
            text: e.message,
            icon: 'CheckSquareIcon',
            variant: 'dander',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.showIcon {
  display: block;
  position: relative;
}

.showIcon:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://img.icons8.com/material-outlined/50/trash--v1.png") no-repeat center center;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease;
}

.showIcon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  opacity: 0;
  transition: opacity 0.4s linear;
}

.showIcon:hover:before,
.showIcon:hover:after {
  opacity: 1;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
